import { useCallback } from 'react';
import emailjs from '@emailjs/browser';

export const useSendEmail = () => {
  const sendReportEmail = useCallback(async (data) => {
    try {
      const res = await emailjs.sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_REPORT_TEMPLATE_ID,
        data,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      );

      return res.status === 200;
    } catch (error) {
      return false;
    }
  }, []);

  const sendInquireEmail = useCallback(async (data) => {
    try {
      const res = await emailjs.sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_INQUIRE_TEMPLATE_ID,
        data,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      );

      return res.status === 200;
    } catch (error) {
      return false;
    }
  }, []);

  const sendSubmitEmail = useCallback(async (data) => {
    try {
      const res = await emailjs.send(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_INQUIRE_TEMPLATE_ID,
        data,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      );

      return res.status === 200;
    } catch (error) {
      return false;
    }
  }, []);

  return { sendReportEmail, sendInquireEmail, sendSubmitEmail };
};
