import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Box, Divider, useToast } from '@chakra-ui/react';
import { EnvelopeSimple } from 'phosphor-react';

import { useSendEmail } from '@hooks';
import { BaseHeading, BaseScene, BaseSeo } from '@components/base';
import { CoreContactUsForm } from '@components/core';

export default function SearchPage() {
  const { sendInquireEmail } = useSendEmail();
  const toast = useToast();

  const handleInquireSubmit = React.useCallback(
    async (data) => {
      const isSuccess = await sendInquireEmail(data);
      toast({
        title: isSuccess ? 'Report Submitted' : 'Sending Failed',
        status: isSuccess ? 'success' : 'error',
        duration: 4000,
        isClosable: true,
      });
    },
    [sendInquireEmail, toast]
  );

  return (
    <BaseScene as='section'>
      <Box marginInline='auto' w={{ base: 'full', sm: 'md' }}>
        <Box pb={4}>
          <BaseHeading as='h2' icon={EnvelopeSimple}>
            Contact Us
          </BaseHeading>
          <Divider pt={1} />
        </Box>
        <CoreContactUsForm onSubmit={handleInquireSubmit} />
      </Box>
    </BaseScene>
  );
}

export function Head({ data }) {
  const { pathname } = useLocation();
  const { siteUrl, siteTitle, datePublished } = data.site.siteMetadata;

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `Contact Us ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified: datePublished,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, datePublished]);

  return (
    <BaseSeo
      title='Contact Us'
      description='Send Us A Message'
      schemaGraphs={schemaGraphs}
    />
  );
}

export const query = graphql`
  query contactQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        datePublished
      }
    }
  }
`;
